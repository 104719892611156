<template>
    <div class="radiotherapy-container">
        <div class="calendar-container">
            <div class="yyyy-MM" @click="DateMMShow = true">
                <span class="">{{getLocalMonth(dateTxt).replace(/\//, '年')}}月</span>
            </div>
            <calendar :defaultTime="dateTxt" :reserveDate="dateRaduitgerapy" :setprojectResult="setprojectResult" />
        </div>
        <div class="radiotherapy-patient" v-if="patientList.date">
            <div class="patient-item" v-for="(item,index) in patientList.patients" :key="index" @click="seeDetail(item)">
                <div class="item-head">
                    <p class="txt">{{item.patientName}}</p>
                    <p class="time">
                        <span>{{item.timeRange}}</span>
                    </p>
                </div>
                <div class="item-info">
                    <div class="info">
                        <p class="info-time">
                            {{$t("radiotherapy.planTime")}}：{{patientList.date}}
                        </p>
                        <p class="info-compuyrRoom">
                            {{$t("radiotherapy.treatmentRoom")}}：{{item.checkRoomName}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <my-overlay :show="DateMMShow" :showField="'DateMMShow'">
            <van-datetime-picker
                v-model="DateRange"
                type="year-month"
                :title='$t("patient.pleaseselect")'
                slot="overBox"
                :min-date="minDate"
                @cancel="DateMMShow = false"
                :max-date="maxDate"
                @confirm="monthConfirm"
            />
        </my-overlay>
    </div>
</template>

<script>
import myOverlay from "@/components/myoverlay"
import calendar from "./components/calendar.vue"
import { getLocalMonth } from "@/utils/date"
import { radiotherapyQuery } from "@/api/user";
export default {
    components:{
        myOverlay,
        calendar
    },
    data () {
        return {
            maxDate: new Date(new Date().getFullYear()+5, 0, 1),
            minDate: new Date(new Date().getFullYear()-5, 0, 1),
            DateRange:new Date(),
            dateTxt:'',
            patientList:{},
            dateRaduitgerapy:[],
            getLocalMonth,
            DateMMShow:false
        }
    },
    created () {
        this.dateTxt = getLocalMonth( new Date().getFullYear() + '/'+(new Date().getMonth()+1))+'/'+new Date().getDate();
        this.getDateQuery();
        document.title = this.$t("radiotherapy.name")
    },
    methods: {
        getDateQuery(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            radiotherapyQuery({}).then(res =>{
                toast.clear()
                this.dateRaduitgerapy = res.data.data;
            })
        },
        monthConfirm(e){
            this.dateTxt =  getLocalMonth(new Date(e).getFullYear() + '/'+(new Date(e).getMonth()+1))+'/'+new Date(e).getDate();
            this.DateMMShow = false;
        },
        setprojectResult(r){
            console.log(r);
            this.patientList = r;
        },
        seeDetail(item){
            this.$router.push({
                path:"/radiotherapy/detail",
                query:{
                    patientId:item.patientId
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.radiotherapy-container{
    height: 100%;
    overflow: auto;
    background: #f5f5f5;
    .calendar-container{
        background: #fff;
        padding: 0.6rem 0.6rem 0 0.6rem;
        margin: 0.2rem 0;
        .yyyy-MM{
            height: 1.2rem;
            line-height: 1.2rem;
            font-size: 0.72rem;
            font-weight: 500;
            line-height: 1.2rem;
            margin-bottom: 0.4rem;
            color: #333333;
            span{
                position: relative;
                &::after{
                    position: absolute;
                    content: ' ';
                    right: -0.6rem;
                    border: 0.25rem solid transparent;
                    border-top: 0.25rem solid #49A7FF;
                    top: 75%;
                    transform: translate(0,-50%);
                }
            }
        }
    }
    .radiotherapy-patient{
        overflow: auto;
        margin: 0.6rem;
        .patient-item{
            margin-bottom: 0.6rem;
            background: #FFFFFF;
            border-radius: 0.16rem;
            .item-head{
                height: 1.6rem;
                position: relative;
                background: url("~@/assets/img/rediotherapy-patient-title.png") no-repeat;
                padding: 0 0.8rem;
                .txt{
                    line-height: 1.6rem;
                    overflow-x: auto;
                    overflow-y: hidden;
                    background-size: 100% 100%;
                    // text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-right: 4.25rem;
                    font-size: 0.72rem;
                    font-weight: 500;
                    color: #333333;
                    
                }
                .time{
                    position: absolute;
                    height: 100%;
                    width: 6rem;
                    right: 0;
                    top: 0;
                    font-size: 0.64rem;
                    font-weight: 500;
                    color: #333333;
                    text-align: right;
                    line-height: 1.6rem;
                    background: url("~@/assets/img/patient-title-time.png") no-repeat;
                    background-size: 100% auto;
                    span{
                        margin-right: 0.8rem;
                    }
                }
            }
            .item-info{
                padding: 0.6rem 0.8rem;
                .info{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 0.56rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #606266;
                    .info-time{

                    }
                    .info-compuyrRoom{

                    }
                }
            }
        }
    }
}
</style>