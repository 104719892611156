<template>
  <div class="rili">
    <!-- 工具栏 star -->
    <!-- <div class="tools">
      <div class="toolsLeft flex">
        <div class="jiantou" @click="arrowLeft">
          <i class="el-icon-caret-left"></i>
        </div>
        <div class="jiantouCenter">
          <p >{{ TIME | defaultTimeFormat }}</p>
        </div>
        <div class="jiantou" @click="arrow">
          <i class="el-icon-caret-right"></i>
        </div>
      </div>
      <div class="toolsRight">
        <div class="btn flex-center" @click="Back">返回今天</div>
      </div>
    </div> -->
    <!-- 工具栏 end -->

    <!-- 日历头部 star  -->
    <div class="title">
      <div v-for="(item, index) in weekTitle" :key="index" class="">
        {{ item }}
      </div>
    </div>
    <!-- 日历头部 end  -->

    <div class="body" :style="{height:isOpen?'1.6rem':''}">
      <!-- 上个月剩余日期 star -->
	  <!-- disabled -->
      <div
        class="box"
		:class="{'disabled':!isOpen}"
        v-for="(item, index) in topMonthList"
        :key="'top' + index"
        @click="arrowLeft(item)"
      >
        <!-- <div class="gongli"> -->
          {{ item.day }}
        <!-- </div> -->
        <!-- <div class="nongli"> -->
          <!-- <span v-if="item.yangEvents.yangeventName" class="bg-blue fw">{{
            item.yangEvents.yangeventName
          }}</span>
          <span v-else>{{ item.calendar.IDayCn }}</span> -->
        <!-- </div> -->
      </div>
      <!-- 上个月剩余日期 end -->
      <!-- 当前月 日期 star -->
      <div
        class="box"
        :class="{
          selected:
             getTimePosition(item) || selecID2 == index,
          custom: item.custom,
          Multiple: item.Multiplem,
          
        }"
        v-for="(item, index) in this_DateList"
        :key="index"
        @click="selectedFun(item, index)"
      >
        <div class="gongli" :class="{'past-time':item.isPastPatient}">
            <span class="day" >
                {{ item.day }}
            </span>
            <!-- <span class="Quota" v-if="!item.isPastTime&&item.projectObj">
                余{{item.projectObj.quota - item.projectObj.usedQuota}}
            </span> -->
        </div>
        <!-- <div class="nongli"> -->
          <!-- <span v-if="item.yangEvents.yangeventName" class="bg-blue fw">{{
            item.yangEvents.yangeventName
          }}</span>
          <span v-else>{{ item.calendar.IDayCn }}</span> -->
        <!-- </div> -->
      </div>
      <!-- 当前月 日期 end -->

      <!-- 下个月剩余日期 star -->
	  <!--  -->
      <div
	  	:class="{'disabled':!isOpen}"
        class="box"
        v-for="(item, index) in bottomMonthList"
        :key="'bottom' + index"
        @click="arrow(item)"
      >
        <div class="gongli" :class="{ 'today fw': item.today }">
          {{ item.day }}
        </div>
        <!-- <div class="nongli"> -->
          <!-- <span v-if="item.yangEvents.yangeventName" class="bg-blue fw">{{
            item.yangEvents.yangeventName
          }}</span>
          <span v-else>{{ item.calendar.IDayCn }}</span> -->
        <!-- </div> -->
      </div>
      <!-- 下个月剩余日期 end -->
    </div>
	<p class="openAndStow" @click="isOpen = !isOpen">
		<!-- openAndStow -->
		<!-- open-icon.png -->

		<img src="@/assets/img/open-icon.png" v-if="isOpen" alt="">
		<img src="@/assets/img/stow-icon.png" v-else alt="">
	</p>
  </div>
</template>

<script>
import calendar from "./calendar.js";
import { getLocalDateTime } from "@/utils/date";
import { getLocalMonth } from "@/utils/date"
export default {
  props: {
    // 默认日期
    defaultTime: {
      type: String,
      default: function () {
        return `${new Date().getFullYear()}-${
          new Date().getMonth() + 1
        }-${new Date().getDate()}`;
        // return "2021-12-1"
      },
    },
    setprojectResult:Function,
    // 设置名额日期
    reserveDate:{
      type:Array,
      default:[]
    },
    startTime:{
      type:String,
      default:"",
    },
    endTime:{
      type:String,
      default:"",
    },
    // 自定义日期
    customList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 是否多选
    Multiple: {
      type: Boolean,
      default: false,
    },
    // 超过当前时间不可选择 默认true可以选择
    historicalDate: {
      type: Boolean,
      default: true,
    },
  },
  filters: {
    // 默认日期过滤
    // defaultTimeFormat: function (value) {
    // //   var defaultTime = value.split("/");
    // //   defaultTime[0] = parseInt(defaultTime[0]);
    // //   defaultTime[1] = parseInt(defaultTime[1]);
    // //   return `${defaultTime[0]}年  ${defaultTime[1]}月`;
    // },
    // // 月选择 日期过滤
    // MonthFormat: function (value) {
    // //   var defaultTime = value.split("/");
    // //   defaultTime[0] = parseInt(defaultTime[0]);
    // //   return `${defaultTime[0]}年`;
    // },
  },
  watch: {
    TIMELIST: function (newVal) {
      newVal[0] = parseInt(newVal[0]);
      newVal[1] = parseInt(newVal[1]);
      newVal[2] = parseInt(newVal[2]);
    },
	reserveDate:function(){
		this.init();
	},
	defaultTime:function(e){
		this.TIME = this.defaultTime;
		this.init()
	},
    TIME: function () {
      this.init();
    },
    customList: function (val) {
      this.CUSTOMLIST = val;
      this.init();
    },
    acticeDate(val){
      let r = this.reserveDate.find(item =>new Date(item.date.replace(/-/g,'/')).getTime() == new Date(this.selectedVal.year+'/'+this.selectedVal.month+'/'+this.selectedVal.day).getTime());
      if(r){
        this.setprojectResult(r)
      }else{
        this.setprojectResult({})
      }
	  
    }
  },

  data() {
    return {
		isOpen:true,
		// 当前选中时间
		acticeDate:null,
		TIME: "", // 获取props的defaultTime
		TIMELIST: [], // 分割日期
		CUSTOMLIST: [], // 获取props的customList
		weekTitle: ["日", "一", "二", "三", "四", "五", "六"],
		MonthBoxBol: false, // 月份弹出层
		this_Year: new Date().getFullYear(), // 当前年
		this_Month: new Date().getMonth() + 1, // 当前月
		this_Day: new Date().getDate(), // 当前日
		// 日期集合
		this_DateList: [], // 当前月日期集合
		topMonthList: [], // 上个月剩余日期集合
		bottomMonthList: [], // 下个月剩余日期集合
		nowTimeDate:[],//本周 日期

		selecID: null, // 当前选中index
		selecID2: null, // 当多选时启用
		selectedVal: null, // 当前选中的信息
		MultipleList: [], // 多选时存放的 开始日期和结束日期
		getLocalDateTime,
    };
  },

  components: {},

  computed: {},

  created() {
	
    this.TIME = this.defaultTime;
    if(new Date(this.startTime).getTime()>new Date().getTime()){
      this.TIME = `${new Date(this.startTime).getFullYear()}-${
          new Date(this.startTime).getMonth() + 1
        }-${new Date(this.startTime).getDate()}`;
    }
    this.CUSTOMLIST = this.customList;
    this.init();
  },

  mounted() {},

  methods: {
    // 返回今天
    Back() {
      this.TIME = `${this.this_Year}-${this.this_Month}-${this.this_Day}`;
      this.$emit("result", {
        year: this.zeroPad(this.this_Year),
        month: this.zeroPad(this.this_Month),
        day: this.zeroPad(this.this_Day),
      });
      this.acticeDate = null;
      this.init();
    },
    // 日期初始化
    init() {
      this.this_DateList = [];
      this.topMonthList = [];
      this.bottomMonthList = [];
      var time = (this.TIMELIST = this.TIME.split("/"));
      time[1] = this.zeroPad(time[1]);
      // 获取上个月剩余日期集合
      var firstDate = new Date(time[0], time[1] - 1).getDay(); // 当前月第一天是星期几
      var topMonthLength = new Date(time[0], time[1] - 1, 0).getDate(); // 上个月月数
      var thisTopMonth = topMonthLength - firstDate; // 求上个月剩余几天
      for (let i = 1; i <= firstDate; i++) {
        this.topMonthList[i - 1] = {
            year: time[1] === 1 ? time[0] - 1 : time[0],
            month: this.zeroPad(time[1] === 1 ? 12 : parseInt(time[1]) - 1), // 如果本月是一月，那么上个月就是去年的12月
            day: this.zeroPad(thisTopMonth + i),
			isPastPatient:this.isPatient((time[1] === 1 ? time[0] - 1 : time[0])+'/'+(this.zeroPad(time[1] === 1 ? 12 : parseInt(time[1]) - 1))+'/'+this.zeroPad(thisTopMonth + i))
        };
        this.topMonthList[i - 1].calendar = calendar.solar2lunar(
          this.topMonthList[i - 1].year,
          this.topMonthList[i - 1].month,
          this.topMonthList[i - 1].day
        ); // 公历转农历
     
        // 是否可选日历日期

        // this.topMonthList[i - 1].historicalBol = this.historicalDate_M('', this.topMonthList[i - 1].month, this.topMonthList[i - 1].day)
      }
      // 获取当前月
      var thisDateLength = new Date(time[0], time[1], 0).getDate();
      for (let i = 1; i <= thisDateLength; i++) {
        this.this_DateList[i - 1] = {
			year: time[0],
			month: time[1],
			day: this.zeroPad(i),
			projectObj:this.getDateProject(time[0]+'/'+time[1]+'/'+this.zeroPad(i)),
			calendar: calendar.solar2lunar(time[0], time[1], i),
        	isPastPatient:this.isPatient(time[0]+'/'+time[1]+'/'+this.zeroPad(i))
        };
          
        this.this_DateList[i - 1].custom = this.getCustomList(
          this.this_DateList[i - 1].year,
          this.this_DateList[i - 1].month,
          this.this_DateList[i - 1].day
        ); // 自定义日期

        // 标记今天
        if (
          this.this_Day === i &&
          this.this_Year === time[0] &&
          this.this_Month === time[1]
        ) {
          this.this_DateList[i - 1].today = true;
		  this.selectedFun(this.this_DateList[i - 1],i-1)
        }

        // if (this.historicalDate) {
        //   continue;
        // }
        // this.this_DateList[i - 1].historicalBol = this.historicalDate_M(
        //   this.this_DateList[i - 1].year,
        //   this.this_DateList[i - 1].month,
        //   this.this_DateList[i - 1].day
        // ); // 自定义日期
      }
      // 获取下个月剩余日期集合
      let reminber = (this.topMonthList.length + this.this_DateList.length)%7;
      let numDay = (parseInt((this.topMonthList.length + this.this_DateList.length)/7) + (reminber!=0?1:0))*7;
      
      var thisBootomMonth =
        numDay - this.topMonthList.length - this.this_DateList.length; // 求下个月剩余几天
      for (let i = 1; i <= thisBootomMonth; i++) {
        this.bottomMonthList[i - 1] = {
          year: time[1] === 12 ? time[0] + 1 : time[0], // 如果本月是十二月，那么下个月就是明年的1月
          month: this.zeroPad(time[1] === 12 ? 1 : parseInt(time[1]) + 1), // 如果本月是十二月，那么下个月就是明年的1月
          day: this.zeroPad(i),
        };

        this.bottomMonthList[i - 1].calendar = calendar.solar2lunar(
          this.bottomMonthList[i - 1].year,
          this.bottomMonthList[i - 1].month,
          this.bottomMonthList[i - 1].day
        ); // 公历转农历

      }
      this.$forceUpdate();
    },
    // 获取这一天的项目预约情况
    getDateProject(dateStr){
      let r = this.reserveDate.find(item =>new Date(item.date.replace(/-/g,'/')).getTime() == new Date(dateStr).getTime());
      return r;
    },
	isPatient(dateStr){
		let r = this.reserveDate.find(item =>new Date(item.date.replace(/-/g,'/')).getTime() == new Date(dateStr).getTime());
		return r?true:false
	},
    isPastTime(time){
        let now = new Date();
        return (new Date(now.getFullYear()+'/'+(now.getMonth()+1)+'/'+now.getDate()).getTime()) > new Date(time);
    },
    // 获取前后年份 生成集合
    yearInit() {},
    // 判断是否历史日期是否可选
    historicalDate_M(y, m, d) {
      var val = `${y}-${m}-${d}`;
      // 当前日期
      var current = `${this.this_Year}-${this.this_Month}-${this.this_Day}`;
      var oDate1 = new Date(val);
      var oDate2 = new Date(current);
      if (oDate1.getTime() < oDate2.getTime()) {
        return true;
      }
      return false;
    },

    // 选中日期事件
    selectedFun(item, index) {
      if(!item.isPastPatient){
        // this.$toast("预约时间不对")
        return false
      }
    //   // 以往日期不可选
    //   if (item.historicalBol) {
    //     return;
    //   }
      var data = [];
      var length = this.this_DateList.length;
      this.acticeDate = item.year+'/'+item.month+'/'+item.day;
      if (this.Multiple) {
        if (
          (this.selecID && this.selecID2) ||
          (this.selecID === 0 && this.selecID2)
        ) {
          this.selecID = this.selecID2 = null;
          this.MultipleList = [];
          for (let i = 0; i < length; i++) {
            this.this_DateList[i].Multiple = false;
          }
        } else if (this.selecID == null) {
          this.selecID = index;
          this.MultipleList[0] = item;
        } else if (this.selecID || this.selecID === 0) {
          this.selecID2 = index;
          this.MultipleList[1] = item;
          data = this.getDiffDate(
            `${this.MultipleList[0].year}/${this.MultipleList[0].month}/${this.MultipleList[0].day}`,
            `${this.MultipleList[1].year}/${this.MultipleList[1].month}/${this.MultipleList[1].day}`
          );

          if (data.length > 0) {
            data.push(
              `${this.MultipleList[1].year}/${this.MultipleList[1].month}/${this.MultipleList[1].day}`
            );
          }

          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < length; j++) {
              if (
                `${this.this_DateList[j].year}/${this.this_DateList[j].month}/${this.this_DateList[j].day}` ===
                data[i]
              ) {
                this.this_DateList[j].Multiple = true;
                continue;
              }
            }
          }

          this.$emit("result", data);
        }
      } else {
        this.selecID = index;
        this.selectedVal = item;
        this.$emit("result", item);
      }
    },
    // 格式化日期
    getDate(datestr) {
      var temp = datestr.split("/");
      if (temp[1] === "01") {
        temp[0] = parseInt(temp[0], 10) - 1;
        temp[1] = "12";
      } else {
        temp[1] = parseInt(temp[1], 10) - 1;
      }
      // new Date()的月份入参实际都是当前值-1
      var date = new Date(temp[0], temp[1], temp[2]);
      return date;
    },
    // 求两日期之间的时间
    getDiffDate(start, end) {
      var startTime = this.getDate(start);
      var endTime = this.getDate(end);
      var dateArr = [];
      while (endTime.getTime() - startTime.getTime() > 0) {
        var year = startTime.getFullYear();
        var month =
          (startTime.getMonth() + 1).toString().length === 1
            ? "0" + (parseInt(startTime.getMonth().toString(), 10) + 1)
            : startTime.getMonth() + 1;
        var day =
          startTime.getDate().toString().length === 1
            ? "0" + startTime.getDate()
            : startTime.getDate();
        dateArr.push(year + "-" + month + "-" + day);
        startTime.setDate(startTime.getDate() + 1);
      }
      return dateArr;
    },
    // 保存当前日期位置

    getTimePosition(selectedVal) {
      if(this.acticeDate){
        var time = this.acticeDate.replace(/-/g,'/').split("/");
        return (
          `${selectedVal.year}-${this.zeroPad(selectedVal.month)}-${this.zeroPad(selectedVal.day)}` ===
          `${time[0]}-${this.zeroPad(time[1])}-${time[2]}`
        );
      }else{
        return false;
      }
    },


    // 获取自定义日期
    getCustomList(y, m, d) {
      var val = `${y}-${m}-${d}`;

      if (Object.keys(this.CUSTOMLIST).length === 0) return false;
      for (let i = 0; i < this.CUSTOMLIST.length; i++) {
        if (val === this.CUSTOMLIST[i]) {
          return this.CUSTOMLIST[i];
        }
      }

      return false;
    },
    // 日期补零
    zeroPad(n) {
      // eslint-disable-next-line no-unused-expressions
      typeof n !== "string" ? (n = String(n)) : n;
      return n.padStart(2, "0");
    },

    // 上一页
    arrowLeft(item) {
      if(item){
        this.acticeDate = item.year+'/'+item.month+'/'+item.day;
        this.selectedVal = item;
        setTimeout(()=>{
          this.selecID = this.this_DateList.findIndex(item => item.day == item.day)
        },100)
      }
      if (this.TIMELIST[1] === 1) {
        this.TIME = `${this.TIMELIST[0] - 1}/12/${this.TIMELIST[2]}`;
      } else {
        this.TIME = `${this.TIMELIST[0]}/${this.TIMELIST[1] - 1}/${
          this.TIMELIST[2]
        }`;
      }
      this.TIMELIST = this.TIME.split("/");
	  this.$parent.dateTxt = this.TIME
    },
    // 下一页
    arrow(item) {
      if(item){
        this.selectedVal = item;
        this.acticeDate = item.year+'/'+item.month+'/'+item.day;
        setTimeout(()=>{
          this.selecID = this.this_DateList.findIndex(item => item.day == item.day)
        },50)
      }
      if (this.TIMELIST[1] === 12) {
        this.TIME = `${this.TIMELIST[0] + 1}/1/${this.TIMELIST[2]}`;
      } else {
        this.TIME = `${this.TIMELIST[0]}/${this.TIMELIST[1] + 1}/${
          this.TIMELIST[2]
        }`;
      }
      this.TIMELIST = this.TIME.split("/");
	  this.$parent.dateTxt = this.TIME
    },

    // 月份弹出层事件
    clickMonth() {
      this.MonthBoxBol = true;
    },
    // 选中月份
    clickMonthBox(val) {
      this.TIME = `${this.this_Year}-${val}/01`;
    },
    // 上一页
    previousPage() {},
    // 下一页
    nextPage() {},
  },
};
</script>
<style lang='scss' scoped>
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.rili {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.rili .title {
	width: 100%;
	height: 1.2rem;
	display: flex;
	font-weight: 500;
	box-sizing: border-box;
	font-weight: 600;
	font-size: 0.56rem;
	font-weight: 500;
	color: #999999;
}
.rili .title div {
  width: calc(100% / 7);
  border: 0.02rem solid #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex {
  display: flex;
}
.body {
	width: 100%;
	font-size: 0.32rem;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	box-sizing: border-box;
	// height: 100%/;
    transition: all .3s linear 0s;
}
.body .box {
  width: calc(100% / 7);
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 0.06rem;
  overflow: hidden;
  font-size: 0.6rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  .gongli{
    display: flex;
    align-items: center;
    justify-content: center;
    .Quota{
      font-size: 0.42rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666;
    }
    .noday{
      // color: #999999;
    }
  }
  .past-time{
    // color: #999999;
	position: relative;
	&::after{
		content: ' ';
		width: 0.16rem;
		height: 0.16rem;
		background: #F56C6C;
		position: absolute;
		left: 50%;
		bottom: 0.15rem;
		transform: translate(-50%,);
		border-radius: 50%;
	}
  }
//   border: 0.02rem solid #fff;
}
// 禁用
.disabled {
  color: rgb(218, 218, 218) !important;
}
// 当前月份禁用
.disabled2 {
  // color: rgb(160, 160, 160) !important;
}
.tools {
  width: 100%;
  display: flex;
  height: 2rem;
  align-items: center;
  font-size: 0.24rem;
  position: relative;
  justify-content: center;
}
.toolsLeft {
  width: max-content;
}
.toolsRight {
  position: absolute;
  right: 0;
}
.toolsRight .btn {
  padding: 0.2rem;
  box-sizing: border-box;
  color: #3e8efa;
  font-size: 0.6rem;
}
.today {
  //border: 1px solid #f89595;
  background: #3783d6;
  //color: rgb(255, 104, 104);
}
.selected {
  .gongli{
    background: #3E8EFA;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    .day{
      font-size: 0.56rem;
      color: #FFFFFF !important;
    }
    .Quota{
      font-size: 0.42rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF !important;
    }
  }
  .past-time{
	&::after{
		background: #fff !important;
	}
  }
}
.nongli {
  font-size: 0.24rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.bg-blue {
  color: rgb(255, 157, 157);
}
.fw {
  font-weight: bold;
}

.jiantou {
  font-size: 0.28rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  padding: 0.6rem;
}
.jiantouCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.68rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

//默认
.spot_border {
  border: 0.02rem solid #999999;
}

.Month {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: all 0.5s;
  z-index: 2;
}
.year {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: all 0.5s;
  z-index: 3;
  overflow: auto;
}
.box1 {
  box-shadow: 0.04rem 0.04rem 0.2rem #d3d2d2;
}

.gongli,
.nongli {
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
//自定义颜色
.custom {
  background: #9bcbff;
}
//多选颜色
.Multiple {
  background: #ff6b57;
}
.openAndStow{
	display: flex;
	align-items: center;
	justify-content: center;
	img{
		width: 0.72rem;
		height: 0.26rem;
		padding: 0.4rem;
	}
}
</style>